import { defineStore } from "pinia";
import router from "@/router";

const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    token: localStorage.getItem("token") || '',
    isLogin: localStorage.getItem("isLogin") || 'false',
    userdata: localStorage.getItem("user") || '{"first_name":"Guest user"}',
    showNotify: false,
    showNotifyMessage: "Su sesion se finalizara por inactividad",
    expire_at: parseInt(localStorage.getItem("expire_at") || '0'),
    // token: "",
    // isLogin: "false",
    // userdata: "",
    // showNotify: false,
    // showNotifyMessage: "Su sesion se finalizara por inactividad",
    // expire_at: 0,
    currentTime: 0,
    toggleNotify: false
  }),
  getters: {
    user(state) {
      return JSON.parse(state.userdata);
    },
    getToken(state) {
      return state.token;
    },
    getShowNotify(state) {
      return state.showNotify;
    },
    getShowNotifyMessage(state) {
      return state.showNotifyMessage;
    },
    getExpireSession(state): number {
      return state.expire_at;
    },
    getCurrentTime(state): number {
      return state.currentTime;
    },
    getToggleNotify(state): boolean {
      return state.toggleNotify;
    }
  },
  actions: {
    setShowNotify(show = true) {
      this.showNotify = show;
    },
    setAvatar(url: string) {
      const parse = JSON.parse(this.userdata)
      parse.avatar = url;
      this.userdata = JSON.stringify(parse)
    },
    setOpenNotify() {
      this.toggleNotify = true;
    },
    setCloseNotify() {
      this.toggleNotify = false;
    },
    setShowNotifyMessage(message: string) {
      this.showNotifyMessage = message;
    },
    login(response: any) {
      this.token = response.token.plainText;

      this.isLogin = "true";
      this.expire_at = response.token.expire;
      this.userdata = JSON.stringify(response.user);
      localStorage.setItem("token", response.token.plainText);
      localStorage.setItem("expire_at", response.token.expire);
      localStorage.setItem("isLogin", "true");
      localStorage.setItem("user", JSON.stringify(response.user));
      if ( response.user.rol === "1"){
        router.replace({ name: "dashboard" });
      }else{
        router.replace({ name: "admin.dashboard" });
      }
    },
    logout() {
      router.replace({ name: "login" });
      this.token = "";
      this.expire_at = 0;
      this.currentTime = 0;
      this.isLogin = "false";
      this.userdata = '{"first_name":"Guest user"}';
      localStorage.removeItem("token");
      localStorage.removeItem("expire_at");
      localStorage.removeItem("isLogin");
      localStorage.removeItem("user");
    },
    setCurrentTime(time: number, type?: string) {
      switch (type) {
        case "add":
          this.currentTime += time;
          break;
        case "sub":
          this.currentTime -= time;
          break;
        default:
          this.currentTime = time;
      }
    }
  },
});

export default useAuthStore;
