import { createRouter, createWebHistory } from "@ionic/vue-router";
import { App, URLOpenListenerEvent } from '@capacitor/app';
import TabsPage from "../views/TabsPage.vue";
import { RouteRecordRaw } from "vue-router";
import useAuthStore from "@/stores/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/PageSignIn.vue"),
  },
  {
    path: "/errors/:code/:message",
    name: "errors",
    component: () => import("@/views/PageError.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/PageSignUp.vue"),
  },
  {
    path: "/transfer/status/:id",
    name: "transfeStatus",
    component: () => import("@/views/status/PageTransferStatus.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () => import("@/views/PageForgotPassword.vue"),
  },
  {
    path: '/transaction/:id',
    name: 'transactionRef',
    component: () => import("@/views/PageTransactionRef.vue"),
  },
  {
    path: '/all-transaction',
    name: 'allTransaction',
    component: () => import("@/views/PageAllTransaction.vue"),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import("@/views/PageAccount.vue"),
  },
  {
    path: '/verifyaccount',
    name: 'verifyaccount',
    component: () => import("@/views/PageVerifyAccount.vue"),
  },
  {
    path: '/help',
    name: 'help',
    component: () => import("@/views/PageHelp.vue"),
  },
  {
    path: '/configurations',
    name: 'pageConfig',
    component: () => import("@/views/PageConfig.vue"),
  },
  {
    path: '/configurations/change-password',
    name: 'pageConfigChangePassword',
    component: () => import("@/views/PageUpdatePassword.vue"),
  },
  {
    path: '/configurations/twofa',
    name: 'pageConfigTwoFa',
    component: () => import("@/views/PageConfigTwoFa.vue"),
  },
  {
    path: "/tabs/",
    component: TabsPage,
    children: [
      {
        path: "",
        redirect: "/tabs/tab1",
      },
      {
        path: "tab1",
        name: "dashboard",
        component: () => import("@/views/PageDashboard.vue"),
      },
      {
        path: "tab2",
        component: () => import("@/views/PageRecharge.vue"),
      },
      {
        path: "tab3",
        component: () => import("@/views/PageWithdraw.vue"),
      },
      {
        path: "tab4",
        component: () => import("@/views/PageTransfer.vue"),
      },
    ],
  },
  //admin routes
  {
    path: "/admin/dashboard",
    name: "admin.dashboard",
    component: () => import("@/views/admin/PageDashboard.vue"),
  },
  {
    path: "/admin/manager/users",
    name: "admin.managerUsers",
    component: () => import("@/views/admin/PageManagerUsers.vue"),
  },
  {
    path: "/admin/manager/user/:id",
    name: "admin.managerUser",
    component: () => import("@/views/admin/PageManagerUser.vue"),
  },
  {
    path: "/admin/manager/transactions/:id?",
    name: "admin.managerTransactions",
    component: () => import("@/views/admin/PageTransactions.vue"),
  },
  {
    path: "/admin/manager/transaction/:id?",
    name: "admin.managerTransaction",
    component: () => import("@/views/admin/PageTransaction.vue"),
  },
  {
    path: "/admin/manager/withdraws/:id?",
    name: "admin.managerWithdraws",
    component: () => import("@/views/admin/PageWithdraws.vue"),
  }
  //404 redirect to login/dashboard
  //{ path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


App.addListener('appUrlOpen', function (event: URLOpenListenerEvent) {
  console.log('App opened with URL: ' + event.url);
  const slug = event.url.split(':/').pop();
  console.log('<slug>', slug)
  if (slug) {
    router.push({
      name: 'transactionRef',
      params: { id: slug.split('?token=')[1].split('&')[0] }
    });
  }
});

router.beforeEach(async (to) => {
  const publicPages = ["/", "/register", "/forgot-password"];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();

  // is login redirect to dashboard
  if (
    (to.name === "login" || to.name === "register") && authStore.isLogin === "true"
  ) {
    return { name: "dashboard" };
  }
  // is no login redirect to login form
  if (authRequired && authStore.isLogin !== "true") {
    return { name: "login" };
  }
});
export default router;
